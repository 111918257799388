import request from '@/utils/request'
const requestApi = {
  wms_transfer_inventory_list: '/wms/warehouse/transfer_inventory/',
  wms_transfer_inventory_create: '/wms/warehouse/transfer_inventory/',
  wms_transfer_inventory_update: '/wms/warehouse/transfer_inventory/',
  wms_transfer_inventory_delete: '/wms/warehouse/transfer_inventory/',
  transfer_inventory_list: '/wms/warehouse/transfer_inventory_id/',
  wms_transfer_inventory_detail_list: '/wms/warehouse/transfer_inventory_detail/',
  wms_transfer_inventory_detail_create: '/wms/warehouse/transfer_inventory_detail/',
  wms_transfer_inventory_detail_delete: '/wms/warehouse/transfer_inventory_detail/',
  wms_transfer_inventory_detail_export: '/wms/warehouse/transfer_inventory_detail/export/'
}

export function transfer_inventory_list (query) {
  return request({
    url: requestApi.wms_transfer_inventory_list,
    method: 'get',
    params: query
  })
}

export function transfer_inventory_detail_list (query) {
  return request({
    url: requestApi.wms_transfer_inventory_detail_list,
    method: 'get',
    params: query
  })
}

export function transfer_inventory_list_id (query) {
  return request({
    url: requestApi.transfer_inventory_list,
    method: 'get',
    params: query
  })
}

export function transfer_inventory_create (data) {
  return request({
    url: requestApi.wms_transfer_inventory_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function wms_transfer_inventory_detail_create (data) {
  return request({
    url: requestApi.wms_transfer_inventory_detail_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function transfer_inventory_update (data, transfer_inventory_id) {
  return request({
    url: requestApi.wms_transfer_inventory_update + transfer_inventory_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function transfer_inventory_delete (transfer_inventory_id) {
  return request({
    url: requestApi.wms_transfer_inventory_delete + transfer_inventory_id + '/',
    method: 'delete'
  })
}
export function transfer_inventory_detail_delete (transfer_inventory_id) {
  return request({
    url: requestApi.wms_transfer_inventory_detail_delete + transfer_inventory_id + '/',
    method: 'delete'
  })
}

export function transfer_inventory_detail_export (parameter) {
  return request({
    url: requestApi.wms_transfer_inventory_detail_export,
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob',
    params: parameter
  })
}
